<script setup lang="ts">
const props = defineProps({
  placeholder: {
    type: String,
    defualt: "",
  },
  height: {
    type: String,
    default: "regular",
  },
  width: {
    type: String,
    default: "",
  },
  font: {
    type: String,
    default: "sans",
  },
  fontSize: {
    type: String,
    default: "base",
  },
  size: {
    type: String || null,
    default: "large",
  },
  min: {
    type: Number,
    required: false,
  },
  max: {
    type: Number,
    required: true,
  },
  isValid: {
    type: Boolean,
    // eslint-disable-next-line vue/no-boolean-default
    default: true,
  },
  typeInput: { type: String, default: "text" },
  styleInput: { type: String, default: "light" },
  outlined: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  filled: { type: Boolean, default: false },
  rounded: {
    type: String || null,
    default: "",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  maxlength: {
    type: [Number, String]
  }
});

const emit = defineEmits(["update:modelValue", "focus", "blur", "input"]);

const {
  placeholder,
  size,
  rounded,
  modelValue,
  outlined,
  disabled,
  filled,
  isValid,
  styleInput,
  font,
  fontSize,
  height,
  width,
} = toRefs(props);

function handler(e) {
  emit("update:modelValue", e.target.value);
}

const _height = computed(() => {
  switch (height.value) {
    case "regular":
      return "h-10 px-4 py-3";
    case "large":
      return "h-12 px-5 py-[14px]";
  }
});
const _width = computed(() => {
  switch (width.value) {
    case "small":
      return "w-62";
    case "regular":
      return "w-82";
    case "large":
      return "w-127";
    case "full":
      return "w-full";
    default:
      "";
  }
});
const _rounded = computed(() => {
  switch (rounded.value) {
    case "":
      return "rounded";
    case "sm":
      return "rounded-sm";
    case "md":
      return "rounded-md";
    case "lg":
      return "rounded-lg";
    case "xl":
      return "rounded-xl";
    case "2xl":
      return "rounded-2xl";
    case "3xl":
      return "rounded-3xl";
    case "full":
      return "rounded-full";
  }
});

const _font = computed(() => {
  switch (font.value) {
    case "FiraSans":
      return "font-firasans";
    case "OpenSans":
      return "font-sans";
    default:  return "font-sans";
  }
});

const _fontSize = computed(() => {
  switch (fontSize.value) {
    case "base":
      return "text-base";
    case "lg":
      return "text-lg";
  }
});

const input = ref(null);

onUnmounted(() => {
  // input.value.removeEventListener('focus', onFocus)
  // input.value.removeEventListener('blur', onUnfocus)
});

const wrapperClasses = computed(() => {
  const classes = [];

  if (styleInput.value === "simple") {
    classes.push("bg-[#F9F9F9] ");
  }
  if (styleInput.value === "shadow") {
    classes.push("bg-white  hover:border hover:border-[#E3E3E3]");
  }
  if (styleInput.value === "header") {
    classes.push(" bg-[#F2F2F7]");
  }
  if (filled.value) {
    classes.push("");
  }
  if (!isValid.value) {
    classes.push("border !border-red");
  }
  return classes;
});

const innerClasses = computed(() => {
  const innerClasses = [];
  if (styleInput.value === "simple") {
    innerClasses.push("bg-[#F9F9F9] placeholder:text-[#6F6F6F]");
  }
  if (styleInput.value === "header") {
    innerClasses.push("bg-[#F2F2F7] placeholder:text-[#BABABC]");
  }
  if (styleInput.value === "header_modile") {
    innerClasses.push("bg-[#F9F9F9] placeholder:text-[#BABABC]");
  }
  if (disabled.value) {
    innerClasses.push("bg-transparent");
  }

  return innerClasses;
});
</script>

<template>
  <div
    class="relative !font-sans cursor-pointer font-normal text-left rounded space-x-4 flex flex-row items-center border border-[#E3E3E3]"
    :class="[wrapperClasses, _rounded, _font, _fontSize, _height, _width]"
    :disabled="disabled"
  >
    <div v-if="$slots.prepend" class="h-full flex items-center my-auto">
      <slot class="w-4 h-4" name="prepend" />
    </div>
    <div class="w-full">
      <input
        ref="input"
        :min="min"
        :max="max"
        :maxlength="maxlength"
        :type="typeInput"
        :placeholder="placeholder"
        class="font-normal overflow-hidden text-left text-[#333333] focus:outline-none w-full leading-none"
        :class="innerClasses"
        :disabled="disabled"
        :value="modelValue"
        @input="handler"
        @focus="onFocus"
      />
    </div>
    <div v-if="$slots.suffix" class="h-full flex items-center">
      <slot name="suffix" />
    </div>

    <template v-if="$slots.popup">
      <slot name="popup" />
    </template>
  </div>
</template>

<style scoped>
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}
</style>
